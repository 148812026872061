import { SentimentWord } from '@capturi/api-conversations'
import { formatTime } from '@capturi/audio'
import { useCurrentUser } from '@capturi/core'
import { useOrganization, useSingleUser } from '@capturi/stores'
import { PlayButton } from '@capturi/ui-components'
import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'
import { scoreToEmoji } from './scoreToEmoji'

type Props = {
  words: SentimentWord[]
  agentUid: string
  handlePlay: (timestamp: number, trackId: string) => void
  isPlayable?: boolean
  hasAudio: boolean
  isPlaying: boolean
  playingTrackId: string
}

const SentimentContents: React.FC<Props> = ({
  words,
  agentUid,
  handlePlay,
  isPlayable,
  hasAudio,
  isPlaying,
  playingTrackId,
}) => {
  const user = useCurrentUser()
  const { organizationType } = useOrganization()
  const { name } = useSingleUser(agentUid)

  return (
    <Table>
      <Thead fontSize="xs" fontWeight="medium">
        <Tr>
          <Th>{isPlayable ? <Trans>Play</Trans> : <Trans>Timestamp</Trans>}</Th>
          <Th>
            <Trans>Word</Trans>
          </Th>
          <Th>
            <Trans>Sentiment</Trans>
          </Th>
          <Th>
            <Trans>Speaker</Trans>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {words.map((w) => {
          const id = `${w.speaker}:${w.word}:${w.when}`
          const isTrackPlaying = isPlaying && id === playingTrackId
          return (
            <Tr
              key={id}
              cursor={isPlayable ? 'pointer' : 'cursor'}
              onClick={() => handlePlay(w.when, id)}
              aria-label={t`Play audio`}
            >
              <Td>
                <Flex alignItems="center">
                  <PlayButton
                    isDisabled={!(user.permissions.playback && isPlayable)}
                    hasAudio={hasAudio}
                    isPlaying={isTrackPlaying}
                    size="sm"
                    mr="4"
                  />
                  <Text>{formatTime(w.when)}</Text>
                </Flex>
              </Td>
              <Td>{w.word}</Td>
              <Td fontSize="3xl">{scoreToEmoji(w.score)}</Td>
              <Td>
                {w.speaker === 'User' ? (
                  <Text>{name}</Text>
                ) : (
                  <>
                    {select(organizationType, {
                      public: 'Citizen',
                      other: 'Customer',
                    })}
                  </>
                )}
              </Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default SentimentContents
