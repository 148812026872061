import analytics from '@capturi/analytics'
import {
  ConversationResponseModel,
  ConversationSentimentResponseModel,
  SentimentWord,
  conversationsAPI,
} from '@capturi/api-conversations'
import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { useAPI } from '@capturi/api-utils'
import { useAudioContext } from '@capturi/audio'
import { useOrganization, useSingleUser } from '@capturi/stores'
import {
  ButtonCheckbox,
  ContentPlaceholder,
  Emoji,
} from '@capturi/ui-components'
import {
  Avatar,
  Box,
  Circle,
  Flex,
  FlexProps,
  Grid,
  Stack,
  StackDivider,
  Text,
  TextProps,
  useCheckboxGroup,
} from '@chakra-ui/react'
import { Trans, plural, select, t } from '@lingui/macro'
import Link from 'components/Link'
import UserAvatar from 'components/UserAvatar'
import React, { useEffect, useMemo, useState } from 'react'

import { Hit } from '../Audio/types'
import SentimentContents from './SentimentContents'

type SentimentViewProps = {
  conversation: ConversationResponseModel
  setHits: (hits: Hit[]) => void
}

const transScore = (score: SentimentScore): string =>
  select(score ?? 'Medium', {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    other: 'Medium',
  })

type ScoreFilterValue = 'all' | 'positive' | 'negative'

const MenuItem: React.FC<
  TextProps & { isActive: boolean; isDisabled?: boolean; count?: number }
> = ({ children, isActive, isDisabled = false, count, onClick, ...props }) => {
  const styleProps: FlexProps = {
    cursor: 'pointer',
    color: isActive ? 'primary.500' : 'inherit',
  }
  if (isDisabled) {
    props.cursor = 'not-allowed'
    props.opacity = '0.6'
  }
  return (
    <Flex
      onClick={(e) => {
        if (isDisabled) return
        onClick?.(e)
      }}
      {...styleProps}
      {...props}
    >
      {children} {count != null ? `(${count})` : ''}
    </Flex>
  )
}

const Sentiments: React.FC<SentimentViewProps> = ({
  conversation,
  setHits,
}) => {
  const { userUid: agentUid, uid, dateTime } = conversation
  const isPlayable = !conversation.deleted
  const { data } = useAPI<ConversationSentimentResponseModel>(
    conversationsAPI.getSentiment(conversation.uid),
  )
  const { isPlaying, play, pause } = useAudioContext(
    `/playback/audio/${conversation.uid}`,
    {
      rollbackSeconds: 3,
    },
  )
  const { organizationType } = useOrganization()
  const [playingTrackId, setPlayingTrackId] = React.useState<string>('')

  const [scoreFilterValue, setScoreFilterValue] =
    useState<ScoreFilterValue>('all')
  const [speakerFilterValues, setSpeakerFilterValues] = useState<
    SentimentSpeaker[]
  >(['User', 'Other'])

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: speakerFilterValues,
    onChange: (values) => {
      setSpeakerFilterValues(values as SentimentSpeaker[])
    },
  })

  const { name, profileImage } = useSingleUser(agentUid)

  const {
    filteredWords,
    filteredPositiveWords,
    filteredNegativeWords,
    counts,
  } = useMemo(() => {
    if (data == null) {
      return {
        filteredWords: [],
        extraWords: [],
        counts: {
          negative: 0,
          positive: 0,
          totalUnfiltered: 0,
        },
      }
    }

    const speakerFilter = (w: SentimentWord): boolean =>
      speakerFilterValues.includes(w.speaker)

    const totalWordsUnfiltered = data.negativeWords.concat(
      data.positiveWords,
    ).length
    const filteredNegativeWords = data.negativeWords.filter(speakerFilter)
    const filteredPositiveWords = data.positiveWords.filter(speakerFilter)

    let filteredWords = [...filteredNegativeWords, ...filteredPositiveWords]
    if (scoreFilterValue === 'negative') {
      filteredWords = filteredNegativeWords
    }
    if (scoreFilterValue === 'positive') {
      filteredWords = filteredPositiveWords
    }

    return {
      filteredWords: filteredWords.sort((a, b) => a.when - b.when),
      filteredPositiveWords: filteredPositiveWords.sort(
        (a, b) => a.when - b.when,
      ),
      filteredNegativeWords: filteredNegativeWords.sort(
        (a, b) => a.when - b.when,
      ),
      counts: {
        negative: filteredNegativeWords.length,
        positive: filteredPositiveWords.length,
        totalUnfiltered: totalWordsUnfiltered,
      },
    }
  }, [data, scoreFilterValue, speakerFilterValues])

  useEffect(() => {
    const positiveHits: Hit[] =
      filteredPositiveWords?.map((word) => ({
        id: word.word + word.speaker + word.when,
        title: word.word,
        timestamp: word.when,
        speakerId: word.speaker,
        shape: 'Circle',
        color: 'segments.secondary.500',
        tooltipIsOpen: true,
        isDimmed: scoreFilterValue === 'negative',
      })) ?? []

    const negativeHits: Hit[] =
      filteredNegativeWords?.map((word) => ({
        id: word.word + word.speaker + word.when,
        title: word.word,
        timestamp: word.when,
        speakerId: word.speaker,
        shape: 'Circle',
        color: 'segments.quinary.500',
        tooltipIsOpen: true,
        isDimmed: scoreFilterValue === 'positive',
      })) ?? []

    const allHits =
      scoreFilterValue === 'positive'
        ? [...negativeHits, ...positiveHits]
        : [...positiveHits, ...negativeHits]
    setHits?.(allHits)
  }, [filteredPositiveWords, filteredNegativeWords, setHits, scoreFilterValue])

  const handlePlay = (timestamp: number, trackId: string): void => {
    if (isPlaying && trackId === playingTrackId) {
      pause()
    } else {
      play(timestamp, true)
      setPlayingTrackId(trackId)
      analytics.event('conversationDetails_sentimentList_playAudio', {
        timestamp,
        uid,
        date: dateTime.toUTCString(),
      })
    }
  }

  if (!data) return null

  return (
    <>
      <Grid
        columnGap={4}
        rowGap={2}
        templateColumns={{ md: '1fr 1fr' }}
        alignItems="stretch"
      >
        <ButtonCheckbox
          {...getCheckboxProps({
            value: 'User',
          })}
        >
          <UserAvatar name={name} profileImage={profileImage} size="sm" />
          <Text fontWeight="medium">{name}</Text>
          <Box flex={1} textAlign="right">
            <Text
              fontWeight="medium"
              textShadow="0px 4px 10px rgba(66, 66, 66, 0.2)"
            >
              {transScore(data.wordScore.user)}
            </Text>
            <Text lineHeight="1.2" color="textMuted" fontSize="sm">
              <Trans>Sentiment</Trans>
            </Text>
          </Box>
        </ButtonCheckbox>
        <ButtonCheckbox
          {...getCheckboxProps({
            value: 'Other',
          })}
        >
          <Avatar size="sm" />
          <Text fontWeight="medium">
            {select(organizationType, {
              public: 'Citizen',
              other: 'Customer',
            })}
          </Text>
          <Box flex={1} textAlign="right">
            <Text
              fontWeight="medium"
              textShadow="0px 4px 10px rgba(66, 66, 66, 0.2)"
            >
              {transScore(data.wordScore.other)}
            </Text>
            <Text lineHeight="1.2" color="textMuted" fontSize="sm">
              <Trans>Sentiment</Trans>
            </Text>
          </Box>
        </ButtonCheckbox>
      </Grid>
      <Box mt={6}>
        {speakerFilterValues.length === 0 ? (
          <NoSpeakerPlaceholder />
        ) : filteredWords.length === 0 ? (
          <NoFilteredWordsPlaceholder
            speakers={speakerFilterValues}
            scoreFilter={scoreFilterValue}
          />
        ) : (
          <Stack isInline spacing={[4, null, null, 8]}>
            <Stack
              spacing={1}
              flex="0 0 auto"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <MenuItem
                isActive={scoreFilterValue === 'all'}
                onClick={() => setScoreFilterValue('all')}
                fontWeight="regular"
                fontSize="md"
                mt="0.35rem"
                mb="2px"
              >
                <Trans>All words</Trans>
              </MenuItem>
              <Stack spacing={2} minW="8em" maxW="16em" flex="0 0 auto" mt={2}>
                <MenuItem
                  isActive={scoreFilterValue === 'positive'}
                  onClick={() => setScoreFilterValue('positive')}
                  count={counts.positive}
                  isDisabled={counts.positive === 0}
                  alignItems="center"
                >
                  <Circle size="10px" bg="segmentSecondary" mr={1} />
                  <Trans>Positive phrases</Trans>
                </MenuItem>
                <MenuItem
                  isActive={scoreFilterValue === 'negative'}
                  onClick={() => setScoreFilterValue('negative')}
                  count={counts.negative}
                  isDisabled={counts.negative === 0}
                  alignItems="center"
                >
                  <Circle size="10px" bg="segmentQuinary" mr={1} />
                  <Trans>Negative phrases</Trans>
                </MenuItem>
              </Stack>
            </Stack>
            <SentimentContents
              words={filteredWords}
              agentUid={agentUid}
              handlePlay={handlePlay}
              isPlayable={isPlayable}
              hasAudio={conversation.hasAudio}
              isPlaying={isPlaying}
              playingTrackId={playingTrackId}
            />
          </Stack>
        )}
      </Box>
    </>
  )
}

export default Sentiments

const renderNoResultsMessage = (
  speakers: SentimentSpeaker[],
  scoreFilter: ScoreFilterValue,
): React.ReactNode => {
  if (scoreFilter === 'all') {
    return t`No words with either positive or negative score were recognized for the selected ${plural(
      speakers.length,
      {
        one: 'speaker',
        other: 'speakers',
      },
    )}.`
  }

  const wordScoreSentiment = select(scoreFilter, {
    positive: 'positive',
    negative: 'negative',
    other: 'unknown',
  })

  return t`No words with ${wordScoreSentiment} score were recognized for the selected ${plural(
    speakers.length,
    {
      one: 'speaker',
      other: 'speakers',
    },
  )}.`
}

const NoFilteredWordsPlaceholder: React.FC<{
  speakers: SentimentSpeaker[]
  scoreFilter: ScoreFilterValue
}> = ({ speakers, scoreFilter }) => {
  return (
    <ContentPlaceholder.Container mt={8} size="md">
      <ContentPlaceholder.Heading>
        <Trans>Medium sentiment</Trans>
        <Emoji symbol="😐" fontSize="4xl" pl={2} />
      </ContentPlaceholder.Heading>
      <ContentPlaceholder.Body>
        {renderNoResultsMessage(speakers, scoreFilter)}
      </ContentPlaceholder.Body>
      <ContentPlaceholder.Footer mt="4">
        <Link
          color="primary.500"
          to="https://docs.google.com/spreadsheets/d/1r32bo7p2_6EJVzMpKWcHSb4u8MtJhxQVAi2lsWb3T3g/edit?pli=1#gid=1879565744"
        >
          <Trans>Read about which words affect sentiment</Trans>
        </Link>
      </ContentPlaceholder.Footer>
    </ContentPlaceholder.Container>
  )
}

const NoSpeakerPlaceholder: React.FC = () => {
  return (
    <ContentPlaceholder.Container mt={8} size="md">
      <ContentPlaceholder.Heading>
        <Trans>Select a speaker</Trans>
        <Emoji symbol="👆" fontSize="4xl" pl={2} />
      </ContentPlaceholder.Heading>
    </ContentPlaceholder.Container>
  )
}
